import React from 'react'
import Header from '../../components/global/header/header'
import Hero from '../../components/heros/alternate'
import TextBlock from '../../components/global/textblock/standard'
import CallToAction from '../../components/global/cta'
import MainContent from '../../components/products/main-content'
import Features from '../../components/products/features'
import Footer from '../../components/global/footer/footer'
import PageTransition from 'gatsby-plugin-page-transitions'
import { withIntl } from '../../i18n'

// Images
import ctaImage from '../../images/7Z8A0083_Output.jpg'
import onlineDistribution from '../../images/business-solutions/online-distribution.jpg'

// Icons
import icon1 from '../../images/lotto-icons/balls.svg'
import icon2 from '../../images/lotto-icons/exchange.svg'
import icon3 from '../../images/lotto-icons/journey.svg'
import icon4 from '../../images/lotto-icons/chart-pie.svg'
import icon5 from '../../images/lotto-icons/credit-card-in.svg'
import icon6 from '../../images/lotto-icons/webpage.svg'



const features =  [

  {
    icon: icon1,
    heading:"lotto_products",    
    content: "lotto_products_para"
 },
 {
    icon: icon2,
    heading:"player_account_management",
    content:"integrate_with_crm_tool_para",
  },
  {
    icon: icon3,
    heading:"affiliate_driven_business",
    content:"grow_your_business_para",
  },
  { 
    icon: icon4,
    heading:"reporting_and_bi",
    content:"parameter_driven_on_screen_para"
  },

  {
    icon: icon5,
    heading:"payments_made_easy",
    content:"seamless_deposits_and_withdrawals_para",
  },
  {
    icon: icon6,
    heading:"look_and_feel_made_easy",
    content:"empower_the_user_para",
  }
]

class ProductPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactOpen: false,
    }
  }

  toggleContact = () => {
    this.setState({
      contactOpen: !this.state.contactOpen
    });
    document.body.classList.toggle('noscroll');
  }

  componentDidMount() {
    if(document.body.classList.contains('noscroll')) {
      document.body.classList.remove('noscroll');
    }
  }

  render() {
    return (<></>);
    return(
      <PageTransition
        defaultStyle={{
          transition: 'all 1100ms cubic-bezier(0.8, 0, 0.2, 1)',
          position: 'relative',
          width: '100%',
          opacity: 0,
          maxWidth: '2000px'

        }}
        transitionStyles={{
          entering: {
            opacity: 1
          },
          entered: {
            opacity: 1
          },
          exiting: {
            opacity: 0,
          }
        }}

        transitionTime={1250}
      >
        <Header
          headerStyle="std"
          contactOpen={this.state.contactOpen}
          action={() => this.toggleContact()}
          {...this.props }
        />

        <Hero
          featImage={onlineDistribution}
          pageTitleSub="online"
          pageTitleMain="distribution"        
        />

        <Features
          subTitle="lottery_and_casino"
          mainTitle="features_include"
          features = {features}
        />

        <CallToAction
          image={ctaImage}
          heading="tailor_made_perfect_fit"
          content="lets_get_in_touch"
          buttonLabel="contact_us"
          action={() => this.toggleContact()}
        />

        <Footer />
      </PageTransition>
    )
  }
}

export default withIntl(ProductPage)